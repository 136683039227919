/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: lighter;
  font-weight: 100, 200, 300, 400, 500, 600, 700, 800, 900;
  src: url('./assets/fonts/lato-v23-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/lato-v23-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/lato-v23-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/lato-v23-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/lato-v23-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/lato-v23-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'FontAwesome';
  src: url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css');
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow: auto;
  font-family: 'Lato', sans-serif;
  letter-spacing: 0.4px;
}

body {
  background-color: #112235;
  color: #bebaad;
}

.container {
  max-width: 900px;
  margin: 20px auto;
  margin-top: 150px;
  min-height: 300px;
  padding: 40px;
  word-spacing: 2px;
  background-color: #213145;
  border-radius: 5px;
}

.mobile-visibility {
  visibility: hidden;
  display: none;
}

/* FONTS */

h1 {
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2rem;
  /* padding: 10px 0; */
  margin-bottom: 10px;
}

h2 {
  font-size: 1.1rem;
  font-weight: 300;
  padding-bottom: 10px;
  margin-top: 10px;
}

p {
  font-size: 1rem;
  font-weight: 100;
  line-height: 1.5rem;
  padding: 10px 0;
}

.row .col b {
  font-weight: 700;
}

.statue_info h5 {
  margin-bottom: 15px;
}

ul.statue_info li {
  margin: 0;
  line-height: 1.6rem;
  padding: 0;
}

li {
  margin: 10px;
  list-style: none;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

/* Links */

link,
a {
  font-size: 1.1rem;
  color: #9c7802;
  text-decoration: none;
}

link,
a:hover {
  color: #c1a613;
  transform: scale(1.05);
}

link,
a:active {
  color: #776919;
  transform: scale(1.04);
}

/* ELEMENTS */

.logo {
  display: flex;
  justify-content: center;
  height: auto;
  width: 70px;
  padding-top: 10px;
  margin: auto;
}

/* MENU */
.nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin: 5px 10px; */
  padding: 5px 10px;
}

.menu-scroll {
  position: fixed;
  top: 0;
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  backdrop-filter: blur(10px);
  background-color: #0d1b2b72;
  z-index: 30;
  transition: transform 1s ease;
  transform: translateY(0%);
  /* animation: showMenu 1s ease; */
}

.hide_menu {
  transform: translateY(-100%);
}

/* @keyframes showMenu {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
} */

.menu-fixed {
  position: fixed;
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 30;
}

.home_text {
  margin-top: 30px;
  text-align: center;
}

.home_text p {
  line-height: 1rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0;
  padding: 10px 0;
}

.col {
  width: 50%;
  margin: auto 0;
}

img.col {
  width: 100%;
  height: 100%;
  max-width: 250px;
  object-fit: contain;
  border-radius: 3px;
}

span {
  display: flex;
  justify-content: flex-end;
  font-size: 1.3rem;
  margin-top: 20px;
}

.form-group label {
  margin-left: 10px;
}

.form-group {
  position: relative;
  max-width: 500px;
  margin: 15px auto;
  color: #a17e08;
}

.form-control {
  background-color: transparent;
  border: 1px solid #a17d088a;
  color: #eee;
  font-size: 1rem;
  margin: 5px 0;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  outline: none;
}

footer {
  max-width: 900px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 30px;
  margin-inline: auto;
  padding: 0 20px;
  color: #d65f1a;
}

footer p {
  color: #bebaad;
  /* padding-left: 240px; */
}

/* BUTTONS */

.btn {
  display: flex;
}

.btn-primary {
  background-color: transparent;
  border: 1px solid #a17e08;
  color: #a17e08;
  padding: 10px 40px;
  border-radius: 5px;
  margin: 0 auto;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #a17d0825;
  border: 1px solid #a17d0879;
  font-weight: 700;
  transform: scale(0.98);
}

.order {
  background-color: #283b52;
  line-height: 40px;
  padding: 7px 12px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.order a:hover {
  background-color: #435a75;
}

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px auto;
  max-width: 500px;
}

.form-group {
  margin-top: 70px;
  line-height: 25px;
  max-width: 500px;
}
.animateIn {
  animation: 1s fadeIn ease-in;
}

/*                     */
/* Slideshow Animation */
/*                     */
.FadeInAndOut {
  -webkit-animation: fadeinout 5s ease-in-out;
  animation: fadeinout 5s ease-in-out;
  animation-iteration-count: infinite;
}

@-webkit-keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  15%,
  85% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  0% {
    opacity: 0;
  }
  15%,
  85% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*             */
/* MEDIA QUERY */
/*             */
@media (max-width: 678px) {
  * {
    font-size: 14px;
  }

  .container {
    margin-top: 100px;
  }

  .menu-scroll {
    position: fixed;
    top: 0;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    backdrop-filter: blur(10px);
    background-color: #0d1b2b72;
    z-index: 10;
    transition: transform 1s ease;
    animation: showMenu 1s ease;
  }

  .menu-fixed {
    position: fixed;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    backdrop-filter: blur(5px);
    background-color: transparent;
    z-index: 10;
  }

  .mobile-visibility {
    visibility: visible;
    display: flex;
  }

  .hidden {
    visibility: hidden;
    display: none;
  }

  .hamburger {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(5, 20, 34, 0.8);
    backdrop-filter: blur(6px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 20;
  }

  .mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    font-size: 2.5rem;
  }

  .fa {
    font-family: 'FontAwesome';
    position: fixed;
    top: 34px;
    right: 40px;
    width: 20px;
    height: auto;
    display: flex;
    color: #9c7802;
    z-index: 20;
  }

  .fa:hover,
  .mobile-menu a:hover {
    cursor: pointer;
  }

  .mobile-menu {
    padding: 6px auto;
    margin: 4px;
  }

  .mobile-logo {
    position: fixed;
    top: 5px;
    left: 30px;
    width: 50px;
    height: auto;
    display: flex;
    margin: 10px;
    z-index: 10;
  }

  .menu-logo {
    display: flex;
    height: auto;
    width: 50px;
    cursor: pointer;
  }

  .btn-primary:hover {
    background-color: #a17d0841;
    transform: scale(0.98);
  }

  .about {
    flex-direction: column;
  }

  p.col {
    width: 100%;
  }

  img.col,
  section.col {
    width: 100%;
    max-width: 300px;
    margin: 20px auto;
  }

  .construction {
    padding: 15px;
  }

  footer {
    flex-direction: column;
    text-align: center;
    padding: 10px;
    line-height: 1.5;
  }

  footer .nav {
    flex-wrap: wrap;
  }
}
